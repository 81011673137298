import React, { useContext, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';

import Layout, { Section } from './layout';
import api from '../../../services/API';
import { LoaderContext } from '../../Loader';

export default function DetalheResgate() {
  const { id } = useParams();

  const { setIsLoading } = useContext(LoaderContext);
  const [rescue, setRescue] = useState({});
  const [offer, setOffer] = useState({});

  async function fetchData() {
    setIsLoading(true);
    const response = await api.get(`/api/resgates/${id}`);

    setRescue(response.data.resgate);
    setOffer(response.data.offer);
    setIsLoading(false);
  }

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Container>
      <Layout>
        <ContentFactory offer={offer} rescue={rescue} />
      </Layout>
    </Container>
  );
}

function ContentFactory({ offer, rescue }) {
  switch (
    offer.reward?.name?.toLowerCase() === 'pilates'
      ? offer.partner.name
      : offer.reward?.name
  ) {
    case 'Meditação':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>
                1 Mês de acesso app <strong>LOJONG</strong>
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem' }}>
              <p>1. Baixe o app e cadastre-se antes de validar seu cupom</p>
              <p>2. Insira seus dados pessoais e clique em começar</p>
              <p>
                3. Com tudo certo, você tem duas maneiras de validar seu cupom
              </p>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ width: '50%' }}>
                <p style={{ fontSize: '1.25rem' }}>
                  <strong>PARA CELULARES ANDROID:</strong>
                </p>
                <p>
                  4. No próprio aplicativo, no menu inferior clique na aba
                  “MAIS”
                </p>
                <p>5. Desça até o final da página e encontre aba “CUPOM”</p>
                <p>6. Insira o código promocional e clique em “CONTINUAR”</p>
                <p>
                  7. Pronto! Agora você poderá usufruir da meditação guiada
                  quando quiser!
                </p>
              </div>
              <div style={{ width: '50%' }}>
                <p style={{ fontSize: '1.25rem' }}>
                  <strong>PARA CELULARES APPLE:</strong>
                </p>
                <p>
                  4. Acesse o site{' '}
                  <a target="_blank" href="https://lojongapp.com/cupom">
                    https://lojongapp.com/cupom
                  </a>
                </p>
                <p>
                  5. Informe o seu e-mail previamente cadastrado e o código
                  promocional, clique em “CONTINUAR”
                </p>
                <p>
                  6. Pronto! Retorne a plataforma e você poderá usufruir da
                  meditação guiada quando quiser!
                </p>
              </div>
            </div>
            <div>
              Lembrando que para ativar o cupom, é preciso inserir todos os
              caracteres, incluindo o hífen (-) Caso você precise de suporte
              entre em contato pelo e-mail:{' '}
              <a href="mailto:contato@lojongapp.com">contato@lojongapp.com</a>{' '}
              ou por mensagem para o nosso WhatsApp:
              <a
                target="_blank"
                href="https://api.whatsapp.com/send?phone=5521995029736"
              >
                +55 (21) 99502-9736
              </a>
            </div>
          </Section>
        </>
      );
    case 'Acesso ao streaming Looke':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>1 Mês de {offer.reward?.name?.toLowerCase()}</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem' }}>
              <p>
                <strong>Já possui uma conta: </strong>
              </p>
              <ol>
                <li>
                  Acesse o site:{' '}
                  <a target="_blank" href="https://www.looke.com.br/">
                    https://www.looke.com.br/
                  </a>
                </li>
                <li>
                  Clique em <strong>Minha Conta</strong> na lateral superior
                  direita da página
                </li>
                <li>
                  No menu lateral esquerdo, clique em <strong>GIFTCODE</strong>{' '}
                  e insira o código recebido
                </li>
                <li>
                  Clique em <strong>RESGATAR</strong> e Pronto! Sua assinatura
                  Looke acabou de ser renovada com sucesso!
                </li>
              </ol>
              <p>
                <strong>Não possui uma conta: </strong>
              </p>
              <ol>
                <li>
                  Acesse o site:{' '}
                  <a target="_blank" href="https://www.looke.com.br/">
                    https://www.looke.com.br/
                  </a>
                </li>
                <li>
                  Clique em <strong>ASSINE JÁ</strong> na lateral superior
                  direita da página
                </li>
                <li>
                  Escolha a assinatura <strong>Plano Looke Mensal</strong>
                </li>
                <li>
                  Insira seus dados pessoais para criar uma conta. Prossiga com
                  a verificação de segurança
                </li>
                <li>
                  Na página de finalização e pagamento, insira seu código
                  promocional no campo <strong>Cupom de desconto</strong>
                </li>
                <li>
                  Clique em <strong>FINALIZAR E ASSINAR</strong> e Pronto! Sua
                  assinatura Looke acabou de ser realizada com sucesso!
                </li>
              </ol>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ul>
                <li>O código é pessoal e intransferível</li>
                <li>Cada perfil no Looke disponibiliza 3 telas simultâneas</li>
                <li>
                  Você pode cancelar a assinatura a qualquer momento pelo site
                  do Looke
                </li>
                <li>Válida 01 utilização por CPF</li>
              </ul>
            </div>
          </Section>
        </>
      );
    case 'Fotolivro':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>1 Pacote de impressão de 30 fotos</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem' }}>
              <ol>
                <li>
                  Acesse o site de resgate da oferta:
                  <a
                    target="_blank"
                    href="https://fotoregistro.com.br/parceria/sherwinwilliams-classic"
                  >
                    https://fotoregistro.com.br/parceria/sherwinwilliams-classic
                  </a>
                </li>
                <li>
                  Clique no botão <strong>“QUERO AGORA!”</strong>
                </li>
                <li>
                  Escolha a cor de capa de sua preferência, na orientação
                  <strong>quadrado</strong>, tamanho <strong>15x15cm</strong> e
                  clique em <strong>“PERSONALIZE AGORA”</strong>
                </li>
                <li>
                  Realize o seu cadastro ou caso já possua, acesse com seu login
                  e senha
                </li>
                <li>Escolha o seu tema preferido e envie suas fotos.</li>
                <li>
                  Você será redirecionado para a página de edição, nela você
                  poderá personalizar seu fotolivro com diversos detalhes como:
                  layout, texto, borda etc.
                </li>
                <li>
                  Durante a edição, a qualquer momento, você poderá visualizar o
                  seu design clicando em <strong>“VISUALIZAÇÃO”</strong>, no
                  canto superior direito
                </li>
                <li>
                  Quando estiver tudo certo, clique em{' '}
                  <strong>“PRÓXIMO”</strong> e confira o seu projeto
                </li>
                <li>
                  Após, clique em <strong>“ADICIONAR AO CARRINHO”</strong>, você
                  será redirecionado para a próxima página
                </li>
                <li>
                  Insira o código promocional que consta nesse voucher no campo
                  <strong>“Cupom de desconto ou vale”</strong>
                </li>
                <li>
                  Insira os dados de entrega e confirme seus dados pessoais,
                  escolha a opção de frete de sua preferência
                </li>
                <li>
                  Selecione a melhor forma de pagamento do frete e finalize a
                  sua compra
                </li>
                <li>Pronto! Agora é só aguardar o seu pedido em casa!</li>
              </ol>
            </div>
          </Section>
        </>
      );
    case 'Impressão de Fotos':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>1 Pacote de impressão de 30 fotos</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem' }}>
              <ol>
                <li>
                  Acesse o site de resgate da oferta:
                  <a
                    target="_blank"
                    href="https://fotoregistro.com.br/parceria/sherwinwilliams-fotos"
                  >
                    https://fotoregistro.com.br/parceria/sherwinwilliams-fotos
                  </a>
                </li>
                <li>
                  Clique no botão <strong>“QUERO AGORA!”.</strong>
                </li>
                <li>
                  3. Você será redirecionado para a página de impressão, o
                  tamanho (10x15) foi escolhido automaticamente, mas você pode
                  escolher a borda e o brilho de sua preferência, clique no
                  botão <strong>“Personalize agora”</strong>.
                </li>
                <li>
                  4. Realize o seu cadastro ou caso já possua, acesse com seu
                  login e senha.
                </li>
                <li>
                  5. Clique em <strong>“ADICIONAR FOTOS”</strong> e escolha as
                  suas 30 fotos favoritas.
                </li>
                <li>
                  6. Quando estiver tudo certo, clique no botão{' '}
                  <strong>“FEITO”</strong>
                  localizado no canto superior direito da tela.
                </li>
                <li>
                  7. Certifique-se de que suas fotos são exibidas corretamente e
                  clique em <strong>“PRÓXIMO”</strong>.
                </li>
                <li>
                  8. Após, clique em <strong>“ADICIONAR AO CARRINHO”</strong>,
                  você será redirecionado para a próxima página.
                </li>
                <li>
                  9. Insira os dados de entrega e confirme seus dados pessoais,
                  escolha a opção de frete de sua preferência.
                </li>
                <li>
                  10. Insira o código promocional que consta nesse voucher no
                  campo <strong>“Cupom de desconto ou vale”</strong>.
                </li>
                <li>
                  11. Selecione a melhor forma de pagamento do frete e finalize
                  a sua compra
                </li>
                <li>12. Pronto! Agora é só aguardar o seu pedido em casa!</li>
              </ol>
            </div>
          </Section>
        </>
      );
    case 'Food Delivery':
      return (
        <>
          <Section title="SEUS DADOS">
            <div
              style={{ padding: '0 1rem', marginTop: '1rem', flexWrap: 'wrap' }}
            >
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>{offer.descricao}</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem' }}>
              <ol>
                <li>Baixe o app iFood em sua loja de aplicativos</li>
                <li>
                  Para resgatar o código, acesse a opção{' '}
                  <strong>“Perfil”</strong> dentro do app
                </li>
                <li>
                  Em seguida selecione a opção <strong>“Pagamentos”</strong>
                </li>
                <li>
                  Em Outros Serviços, selecione a opção{' '}
                  <strong>“Resgatar iFood Card”</strong>
                </li>
                <li>
                  Digite o código promocional e selecione{' '}
                  <strong>“Continuar”</strong>
                </li>
                <li>
                  Pronto! Seu saldo será atualizado e ficará disponível na
                  carteira para usar quando quiser!
                </li>
                <li>
                  O saldo possui validade de 90 dias a partir da data de
                  inclusão na carteira
                </li>
              </ol>
            </div>
          </Section>
        </>
      );
    case 'Ingresso de Cinema':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>1 Ingresso de cinema Cinemark</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <p>
                <strong>No APP Cinemark:</strong>
              </p>
              <ol>
                <li>Baixe ou atualize seu APP Cinemark</li>
                <li>Selecione o cinema desejado</li>
                <li>
                  Selecione o filme que gostaria de assistir, o horário da
                  sessão e o formato correspondente ao seu ingresso (2D)
                </li>
                <li>Selecione o assento</li>
                <li>Faça o login (caso não tenha feito)</li>
                <li>
                  Insira o código no campo “Cinemark Voucher” 7. Apresente seu
                  voucher online diretamente na entrada das salas do cinema
                  selecionado.
                </li>
              </ol>
              <p style={{ color: '#ff0000', fontWeight: 'bold' }}>
                <strong>
                  Não são aceitos códigos impressos, nem “prints” de tela.
                </strong>
              </p>
              <p>
                <strong>Na bilheteria:</strong>
              </p>
              <ol>
                <li>Informe na bilheteria o Código do Cinemark Voucher</li>
                <li>
                  Selecione o filme que gostaria de assistir, o horário da
                  sessão e o formato correspondente ao seu ingresso (2D)
                </li>
                <li>
                  Selecione o assento 4. O voucher será disponibilizado impresso
                  para liberação do acesso ao filme.
                </li>
              </ol>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA"></Section>
        </>
      );
    case 'Planilha de Corrida Personalizada':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>1 Planilha Personalizada de Corrida para 1 pessoa.</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ol>
                <li>
                  Acesse o site de resgate da oferta:{' '}
                  <a target="_blank" href="https://bit.ly/4b8NhmA">
                    https://bit.ly/4b8NhmA
                  </a>
                </li>
                <li>
                  Você será redirecionado para o WhatsApp do{' '}
                  <strong>30Tododia</strong>
                </li>
                <li>
                  Entre em contato e siga o fluxo de atendimento respondendo às
                  perguntas
                </li>
                <li>
                  Você deverá informar nome completo e cupom promocional
                  recebido
                </li>
                <li>
                  Também será questionado o nível de treino que você se encontra
                  e a frequência semanal que você pretende realizar a atividade
                </li>
                <li>
                  Após enviar todas as informações, aguarde, você receberá sua
                  planilha de treino personalizada que poderá ser baixada
                  através do WhatsApp.
                </li>
              </ol>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ul>
                <li>
                  Só será disponibilizada{' '}
                  <strong>uma planilha por telefone/nome cadastrado</strong>,
                  sendo assim, após receber o treino, não será possível baixar
                  outro treino de forma gratuita.
                </li>
                <li>
                  Não será oferecido suporte no WhatsApp do 30Tododia para os
                  treinos.
                </li>
                <li>
                  Todas as informações sobre controle da intensidade, volume e
                  distribuições dos treinos estão expressas de forma bem clara
                  na planilha, e foram desenvolvidas por treinadores experientes
                  e são ajustadas ao nível de treino do indivíduo, não sendo
                  responsabilidade do 30Tododia, problemas oriundos do mal uso
                  das informações contidas na planilha.
                </li>
              </ul>
            </div>
          </Section>
        </>
      );
    case 'Uber car':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>{offer.descricao}</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ol>
                <li>
                  Acesse no link que consta nesse voucher e clique em “Adicionar
                  voucher”.
                </li>
                <li>
                  Entre em sua conta ou cadastre-se se ainda não possuir uma.
                </li>
                <li>
                  O voucher será adicionado automaticamente à sua conta e
                  aplicado à próxima viagem qualifi cada.
                </li>
              </ol>
              <p>
                <strong>
                  Certifique-se de star utilizando a versão mais recente do
                  aplicativo Uber.
                </strong>
              </p>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ul>
                <li>Esse voucher pode ser utilizado em todo APP Uber.</li>
                <li>Cobertura: consoante cobertura da Uber.</li>
                <li>
                  O crédito fi cará disponível para pagar as corridas efetuadas
                  com o aplicativo.
                </li>
                <li>
                  O saldo não poderá ser cancelado, trocado, devolvido ou ser
                  convertido em dinheiro.
                </li>
                <li>
                  O saldo não utilizado não poderá ser transferido para outra
                  conta.
                </li>
                <li>
                  Seu cupom de desconto tem validade de 03 meses, e deverá ser
                  utilizado de acordo com este prazo e Termos de Uso.
                </li>
              </ul>
            </div>
          </Section>
        </>
      );
    case 'Uber car':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>{offer.descricao}</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ol>
                <li>
                  Acesse no link que consta nesse voucher e clique em “Adicionar
                  voucher”.
                </li>
                <li>
                  Entre em sua conta ou cadastre-se se ainda não possuir uma.
                </li>
                <li>
                  O voucher será adicionado automaticamente à sua conta e
                  aplicado à próxima viagem qualifi cada.
                </li>
              </ol>
              <p>
                <strong>
                  Certifique-se de star utilizando a versão mais recente do
                  aplicativo Uber.
                </strong>
              </p>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ul>
                <li>Esse voucher pode ser utilizado em todo APP Uber.</li>
                <li>Cobertura: consoante cobertura da Uber.</li>
                <li>
                  O crédito fi cará disponível para pagar as corridas efetuadas
                  com o aplicativo.
                </li>
                <li>
                  O saldo não poderá ser cancelado, trocado, devolvido ou ser
                  convertido em dinheiro.
                </li>
                <li>
                  O saldo não utilizado não poderá ser transferido para outra
                  conta.
                </li>
                <li>
                  Seu cupom de desconto tem validade de 03 meses, e deverá ser
                  utilizado de acordo com este prazo e Termos de Uso.
                </li>
              </ul>
            </div>
          </Section>
        </>
      );
    case 'ACTIVE FITNESS':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>{offer.descricao}</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ol>
                <li>
                  Acesse o site de resgate da oferta:{' '}
                  <a href="https://www.acfit2.com/sherwin" target="_blank">
                    https://www.acfit2.com/sherwin
                  </a>
                </li>
                <li>
                  Clique em <strong>“RESGATE SEU PRÊMIO”</strong>.
                </li>
                <li>
                  Insira seus dados pessoais e o código promocional que consta
                  nesse voucher e clique em{' '}
                  <strong>“RESGATAR GRATUITAMENTE”</strong>.
                </li>
                <li>
                  Após abrir a nova janela, clique no botão{' '}
                  <strong>“OBTER GRATUITAMENTE”</strong>
                </li>
                <li>
                  Na janela que abrirá clique no botão{' '}
                  <strong>“CONFIRMAR”</strong>.
                </li>
                <li>
                  Será exibida uma mensagem informando que seu código foi
                  resgatado com sucesso.
                </li>
                <li>
                  Você receberá em sua caixa de e-mail uma mensagem de
                  boas-vindas, com as instruções de acesso. Caso não tenha
                  recebido, verifique a caixa de <strong>spam</strong> ou{' '}
                  <strong>lixo eletrônico</strong>, pode ter chegado por lá.
                </li>
                <li>
                  Caso já possua uma conta cadastrada na plataforma{' '}
                  <strong>NUTROR</strong>
                  clique no botão <strong>“ACESSAR CURSO”</strong> e acesse com
                  seu usuário e senha.
                </li>
                <li>
                  Se esse for o seu primeiro acesso, clique no link informado
                  abaixo e siga as instruções para se cadastrar:
                  <a
                    href="https://accounts.eduzz.com/53124931-1a7a-424b-aca7-a2eb91fd5b20/
                    login?isPartnerCreate=true&isPartnerLogin=true&logo=https%3A%2F%2Forbita.eduzz.
                    com%2Fimg%2Flogo-myeduzz-login.png&redirectTo=https%3A%2F%2Forbita.eduzz.
                    com%2F%3Fregister%3Dtrue&register=true"
                    target="_blank"
                  >
                    https://accounts.eduzz.com
                  </a>
                </li>
                <li>
                  Pronto! Agora você tem acesso a plataforma e pode aproveitar
                  seu prêmio!
                </li>
              </ol>
              <p>
                Caso você precise de suporte entre em contato pelo e-mail:
                <a href="mailto:suporte@acfit2.com">suporte@acfit2.com</a>
              </p>
            </div>
          </Section>
        </>
      );
    case 'Aula de Música':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>{offer.descricao}</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ol>
                <li>
                  Acesse o link:
                  <a
                    target="_blank"
                    href="https://www.parceiroschoolofrock.com.br/u/sherwin-williams"
                  >
                    https://www.parceiroschoolofrock.com.br/u/sherwin-williams
                  </a>
                </li>
                <li>
                  Clique em <strong>"INSCREVA-SE"</strong>
                </li>
                <li>
                  Efetue o cadastro informando seus dados pessoais e um cartão
                  de crédito válido e clique em{' '}
                  <strong>“FINALIZAR PAGAMENTO”</strong>.
                </li>
                <li>
                  A inclusão de dados de pagamento é somente para fins de
                  cadastro e não será debitado qualquer valor pelos 3 meses de
                  assinatura
                </li>
              </ol>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA">
            <p style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ul>
                <li>
                  A assinatura não tem fidelidade e pode ser cancelada a
                  qualquer momento.
                </li>
                <li>
                  Necessário o cancelamento após o 3º mês para que não haja
                  cobranças nos meses futuros.
                </li>
                <li>
                  Caso você precise de suporte entre em contato pelo e-mail:
                  atendimento@schoolofrockplay.com.br
                </li>
              </ul>
            </p>
          </Section>
        </>
      );
    case 'Voucher Campanha':
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>1 Voucher no valor de R$ 25,00</p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="COMO UTILIZAR SEU CÓDIGO">
            <div style={{ marginTop: '1rem', padding: '0 1rem' }}>
              <ol>
                <li>
                  Para a resgate do valor, o Participante deverá acessar o Site:
                  <a href="https://www.atusmkt.com.br/experienciasw" target='_blank'>
                    <strong> www.atusmkt.com.br/experienciasw</strong>
                  </a>
                </li>
                <li>
                  Deverá preencher os seguintes campos: <br />
                  Nome completo; data de nascimento; número de celular; CPF/ME;
                  e-mail;
                  <br /> Chave PIX válida; Agência e conta bancária (em caso de
                  transferência via TED) e <br />
                  Código de Resgate: Código promocional.
                </li>
                <p>
                  <strong>OPÇÕES PARA RESGATE:</strong>
                  <ul style={{ marginTop: '1rem', padding: '0 1rem' }}>
                    <li>
                      <strong>Chave Pix</strong>: <br />
                      Tarifa de envio PIX Unidade R$1,75
                    </li>
                    <li>
                      <strong>TED</strong>: <br />
                      Tarifa de envio TED Unidade R$5,70
                    </li>
                    <li>
                      <strong>Cartão Pré Pago Virtual PrixCard:</strong> <br />
                      <table
                        cellPadding={'20px'}
                        style={{
                          border: 'none',
                          borderCollapse: 'unset',
                          borderSpacing: '10px',
                        }}
                      >
                        <tr>
                          <td>Saque no Banco 24 Horas </td>
                          <td>Não se aplica</td>
                        </tr>
                        <tr>
                          <td>Compras na rede Mastercard </td>
                          <td>Isento</td>
                        </tr>
                        <tr>
                          <td>Transferência (DOC/TED) via app</td>
                          <td>R$ 9,90 por transferência</td>
                        </tr>
                        <tr>
                          <td>Compras online</td>
                          <td>Isento</td>
                        </tr>
                        <tr>
                          <td>Pagamentos via site (contas e boletos)</td>
                          <td>Isento</td>
                        </tr>
                        <tr>
                          <td>Consulta de saldo/extratos via site</td>
                          <td>Isento</td>
                        </tr>
                        <tr>
                          <td>Depósito via Boleto (Banco Digital)</td>
                          <td>Isento</td>
                        </tr>
                      </table>
                    </li>
                  </ul>
                </p>
              </ol>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA">
            <ul style={{ marginTop: '1rem', padding: '0 2rem' }}>
              <li>Promoção Pintou Prêmio Experiência SW - Confira o Regulamento;</li>
              <li>O código é pessoal e intransferível;</li>
              <li>Oferta válida por tempo determinado enquanto durarem os estoques;</li>
              <li>Cada Participante poderá receber 3 vouchers semanais e ou R$75 (setenta e Cinco) reais;</li>
              <li>limitando-se a até 12 (doze) vouchers mensais e ou R$300 (trezentos) reais.</li>
            </ul>
          </Section>
        </>
      );
    default:
      return (
        <>
          <Section title="SEUS DADOS">
            <div style={{ padding: '0 1rem', marginTop: '1rem' }}>
              <div style={{ display: 'flex', gap: '2rem' }}>
                <span>
                  <strong>NOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(0)}
                  </span>
                </span>
                <span>
                  <strong>SOBRENOME:</strong>{' '}
                  <span style={{ wordWrap: 'break-word' }}>
                    {rescue.nome?.split(/ /).at(-1)}
                  </span>
                </span>
              </div>
              <p>
                <strong>E-MAIL:</strong>{' '}
                <span style={{ wordWrap: 'break-word' }}>{rescue.email}</span>
              </p>
            </div>
          </Section>
          <Section title="SEU PRÊMIO">
            <div
              style={{
                padding: '0 1rem',
                color: '#333',
                marginTop: '1rem',
              }}
            >
              <p>
                <strong>ESTABELECIMENTO: </strong> {offer.partner?.name}
              </p>
              <p>
                <strong>ENDEREÇO: </strong>{' '}
                {`${offer.location?.address1} - ${offer.location?.address2},
                          ${offer.location?.city} - ${offer.location?.region}, ${offer.location?.postCode}`}
              </p>
              <p>
                <strong>TELEFONE: </strong> {offer.location?.phoneNumber}
              </p>
              <p>
                <strong>DETALHES DA OFERTA: </strong> {offer.descricao}
              </p>
            </div>
            <div
              style={{
                display: 'flex',
                width: '100%',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <p
                style={{
                  fontSize: '1.5rem',
                  fontWeight: 'bold',
                  margin: '1rem 0',
                }}
              >
                CÓDIGO PROMOCIONAL
              </p>
              <p
                style={{
                  color: '#333',
                  fontWeight: 'bold',
                  padding: '0.5rem 1rem',
                  border: '1px solid #333',
                  borderRadius: '0.5rem',
                }}
              >
                {rescue.redemptionCode}
              </p>
            </div>
          </Section>
          <Section title="CONDIÇÕES DA OFERTA">
            <ul style={{ padding: '1rem' }}>
              <li>O Voucher é pessoal e intransferível.</li>
              <li>
                Sessões estão sujeitas à disponibilidade de vagas, datas e
                horários do estabelecimento selecionado;
              </li>
              <li>
                Após ser efetuado o agendamento, eventual cancelamento ou
                possibilidade de alteração da data agendada deverá ser tratada
                diretamente com o estabelecimento credenciado escolhido. Caso
                você não compareça na data e horário agendados, perderá o
                direito de usufruir do Benefício;
              </li>
              <li>
                O portador deste voucher não terá preferência sobre os outros
                clientes dos estabelecimentos credenciados. Será garantido,
                entretanto, que você será atendido com a mesma qualidade dos
                demais clientes;
              </li>
            </ul>
          </Section>
        </>
      );
  }
}
