/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Container } from 'react-bootstrap';
import api from '../../../services/API';
import ModalMidia from '../ModalMidia';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { Row, Col } from 'react-bootstrap';
import moment from 'moment-timezone';
const { SearchBar } = Search;

export default function PontuacoesEncerradas() {
  const [data, setData] = useState([]);
  const [mostrarModalFoto, setMostrarModalFoto] = useState(false);
  const [arquivo, setArquivo] = useState([]);
  const [redesSelect, setRedesSelect] = useState([]);
  const [redeSelecionada, setRedeSelecionada] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [tipo, setTipo] = useState('');

  const buscarRedes = async () => {
    const resultado = await api.get('/api/redes?ativo=1');
    if (resultado) {
      // console.log(resultado)
      setRedesSelect(resultado.data.redes);
    }
  };

  useEffect(() => {
    buscarRedes();
    buscarPontuacoes();
  }, []);

  const columns = [
    {
      text: 'Vendedor Loja',
      dataField: 'parceiro',
      sort: true,
    },
    {
      text: 'CPF',
      dataField: 'cpf',
      formatter: (celula, valor) => {
        let cpf = valor.cpf.replace(/[^\d]/g, '');
        return cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
      },
    },
    {
      text: 'Código Loja',
      dataField: 'codRevenda',
      sort: true,
    },
    {
      text: 'Loja',
      dataField: 'revenda',
      sort: true,
    },
    {
      text: 'Data do Envio',
      dataField: 'criadoEm',
      formatter: (celula, valor) => {
        return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Data do Cancelamento',
      dataField: 'atualizadoEm',
      formatter: (celula, valor) => {
        return moment(valor.atualizadoEm).format('DD/MM/YYYY HH:mm:ss');
      },
      sort: true,
    },
    {
      text: 'Foto da Venda',
      dataField: 'dirFoto',
      formatter: (celula, valor) =>
        valor.diretorioArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              visualizarModalFoto(valor.diretorioArquivo, valor.tipo);
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
  ];

  const defaultSorted = [
    {
      dataField: 'criadoEm',
      order: 'desc',
    },
  ];

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const resultado = await api.get(
      `/api/pontuacoes/encerradas?rede=${redeSelecionada}`
    );
    if (resultado) {
      setData(resultado.data);
    }
    setBuscando(false);
  };

  const visualizarModalFoto = (dirFoto, tipo) => {
    if (dirFoto.toLowerCase().endsWith('.pdf')) {
      window.open(`${process.env.REACT_APP_API_BASE_URL}/arquivos/${dirFoto}`);
    } else {
      setArquivo(dirFoto);
      setTipo(tipo);
      setMostrarModalFoto(true);
    }
  };
  const fecharModalFoto = () => {
    setMostrarModalFoto(false);
  };

  useEffect(() => {
    if (buscando) {
      setData([]);
    }
  }, [buscando]);

  const options = {
    sizePerPage: 10,
    showTotal: true,
    sizePerPageList: [
      {
        text: '5',
        value: 5,
      },
      {
        text: '10',
        value: 10,
      },
      {
        text: '50',
        value: 50,
      },
      {
        text: '100',
        value: 100,
      },
      {
        text: 'Todos',
        value: data.length,
      },
    ],
    paginationTotalRenderer: (from, to, size) =>
      size > 0 ? (
        <span className="react-bootstrap-table-pagination-total px-2">
          {from}-{to} de {size} resultados
        </span>
      ) : (
        <span className="react-bootstrap-table-pagination-total px-2">
          Nenhum resultado
        </span>
      ),
  };

  return (
    <Container fluid>
      <h4 className="pt-3 pb-3 f-black">Pontuações Encerradas</h4>
      <hr className="hr-loja mt-0 mb-4" />
      <Row>
        <Col xs={12} sm={6} md={4} lg={3} className="d-flex flex-column">
          <label htmlFor="redeSelecionada">Selecione uma Rede</label>
          <select
            className="input-select mb-3"
            name="redeSelecionada"
            id="redeSelecionada"
            onChange={(e) => setRedeSelecionada(e.target.value)}
            defaultValue=""
            placeholder=""
          >
            <option value="">Todas</option>
            {redesSelect.map((rede) => (
              <option value={rede.nome} key={rede.nome}>
                {rede.nome}
              </option>
            ))}
          </select>
        </Col>
        <Col
          xs={12}
          sm={1}
          className="d-flex flex-column justify-content-center align-items-center d-sm-block"
        >
          <button
            className="btn-busca"
            type="submit"
            onClick={buscarPontuacoes}
          >
            {buscando ? (
              <i className="fas fa-circle-notch fa-spin"></i>
            ) : (
              <i className="fas fa-search"></i>
            )}
          </button>
        </Col>
      </Row>
      <div>
        <ToolkitProvider
          bootstrap4
          keyField="id"
          data={data}
          columns={columns}
          search
        >
          {(props) => (
            <div>
              <div className="d-flex flex-row-reverse">
                <SearchBar
                  {...props.searchProps}
                  placeholder="Buscar"
                  className="input-theme mr-2"
                />
              </div>
              <BootstrapTable
                noDataIndication={() =>
                  buscando ? (
                    <span>
                      Carregando
                      <i className="fas fa-circle-notch fa-spin ml-3"></i>
                    </span>
                  ) : (
                    <span>Sem resultados</span>
                  )
                }
                classes="table-dark text-center rounded"
                wrapperClasses="rounded"
                bordered={false}
                pagination={paginationFactory(options)}
                defaultSorted={defaultSorted}
                {...props.baseProps}
              />
            </div>
          )}
        </ToolkitProvider>
      </div>
      <ModalMidia
        show={mostrarModalFoto}
        onHide={fecharModalFoto}
        diretorioArquivo={arquivo}
        tipo={tipo}
      />
    </Container>
  );
}
