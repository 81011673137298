import moment from 'moment-timezone';
import React, { useEffect, useState } from 'react';
import { Container, Modal } from 'react-bootstrap';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import api from '../../../services/API';
import sortFunc from '../../../utils/ordernarColunaInt';
import './styles.css';

const formatarPontos = (celula, valor) => {
  return Number(valor.totalPontos).toLocaleString('pt-BR');
};

const formatarDataValidade = (celula, valor) => {
  return moment(valor.venceEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarData = (celula, valor) => {
  return moment(valor.pontuadoEm).format('DD/MM/YYYY HH:mm:ss');
};

const formatarDataCriacao = (celula, valor) => {
  return moment(valor.criadoEm).format('DD/MM/YYYY HH:mm:ss');
};


export default function Pontuacoes() {
  const columns = [
    {
      dataField: 'totalPontos',
      text: 'Pontos',
      formatter: formatarPontos,
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'pontuadoEm',
      text: 'Data da Pontuação',
      formatter: formatarData,
      sort: true,
    },
    {
      dataField: 'validadePontos',
      text: 'Vencimento da Pontuação',
      formatter: formatarDataValidade,
    },
    {
      dataField: 'diretorioArquivo',
      text: 'Foto',
      formatter: (celula, valor) => {
        return (
          <button
            style={{ border: 'none', background: 'none' }}
            onClick={() => {
              setShowModal(true);
              setDiretorioArquivo(valor.diretorioArquivo);
            }}
          >
            <i class="fas fa-image"></i>
          </button>
        );
      },
    },
  ];

  const columnsAnalise = [
    {
      dataField: 'criadoEm',
      text: 'Em análise desde',
      formatter: formatarDataCriacao,
      sort: true,
    },
    {
      dataField: 'diretorioArquivo',
      text: 'Foto',
      formatter: (celula, valor) => {
        return (
          <button
            style={{ border: 'none', background: 'none' }}
            onClick={() => {
              setShowModal(true);
              setDiretorioArquivo(valor.diretorioArquivo);
            }}
          >
            <i class="fas fa-image"></i>
          </button>
        );
      },
    },
  ];

  const columnsNaoProcessadas = [
    {
      dataField: 'dataEnvio',
      text: 'Data de Envio',
      formatter: (celular, valor) => moment(valor.dataEnvio).format('DD/MM/YYYY HH:mm:ss'),
      sortFunc: sortFunc,
      sort: true,
    },
    {
      dataField: 'status',
      text: 'Status',
      sort: true,
    },
    {
      dataField: 'reprovadoMotivo',
      text: 'Observação',
      sort: true,
    },
    {
      text: 'Foto',
      dataField: 'dirFoto',
      formatter: (celula, valor) =>
        valor.diretorioArquivo ? (
          <button
            className="btn-limpo f-12 p-1"
            onClick={() => {
              setDiretorioArquivo(valor.diretorioArquivo)
              setShowModal(true)
            }}
          >
            <i className="fas fa-image"></i>
          </button>
        ) : (
          <button className="btn-limpo f-12 p-1" disabled>
            SEM FOTO
          </button>
        ),
    },
  ];

  useEffect(() => {
    buscarPontuacoes();
    buscarNaoProcessadas();
  }, []);

  const buscarPontuacoes = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes');
    if (resultado) {
      setData(resultado.data.pontuacoes);
      setDataAnalise(resultado.data.analise);
    }
    setBuscando(false);
  };

  const [data, setData] = useState([]);
  const [dataAnalise, setDataAnalise] = useState([]);
  const [dataNaoProcessadas, setDataNaoProcessadas] = useState([]);
  const [buscando, setBuscando] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [diretorioArquivo, setDiretorioArquivo] = useState('');

  const buscarNaoProcessadas = async () => {
    setBuscando(true);
    const resultado = await api.get('/api/pontuacoes/nao-processadas');

    if (resultado) {
      setDataNaoProcessadas(resultado.data.naoProcessadas);
    }
    setBuscando(false);
  };

  return (
    <>
      <Container className="mt-5 mb-5">
        <div>
          <h5>PONTUAÇÕES</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <BootstrapTable
            bootstrap4
            noDataIndication={() =>
              buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            className="tabela-fundo-claro"
            keyField="id"
            data={data}
            columns={columns}
            pagination={paginationFactory()}
            headerClasses="tabela-header-claro"
            bodyClasses="tabela-body-claro"
          />
        </div>
        <div style={{ marginTop: '2rem' }}>
          <h5>EM ANÁLISE</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <BootstrapTable
            bootstrap4
            noDataIndication={() =>
              buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            className="tabela-fundo-claro"
            keyField="id"
            data={dataAnalise}
            columns={columnsAnalise}
            pagination={paginationFactory()}
            headerClasses="tabela-header-claro"
            bodyClasses="tabela-body-claro"
          />
        </div>
        <div>
          <h5>NOTAS NÃO PROCESSADAS</h5>
          <hr className="hr-loja mt-0" />
        </div>
        <div>
          <BootstrapTable
            bootstrap4
            noDataIndication={() =>
              buscando ? (
                <span>
                  Carregando
                  <i className="fas fa-circle-notch fa-spin ml-3"></i>
                </span>
              ) : (
                <span>Sem resultados</span>
              )
            }
            className="tabela-fundo-claro"
            keyField="id"
            data={dataNaoProcessadas}
            columns={columnsNaoProcessadas}
            pagination={paginationFactory()}
            headerClasses="tabela-header-claro"
            bodyClasses="tabela-body-claro"
          />
        </div>
      </Container>
      <Modal
        show={showModal}
        onHide={() => setShowModal(false)}
        size="lg"
        centered
      >
        <Modal.Header closeButton />
        <Modal.Body>
          {diretorioArquivo.split('.').at(-1).toLowerCase() === 'pdf' ? (
            <iframe
              width="100%"
              height={700}
              src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${diretorioArquivo}`}
              frameborder="0"
            ></iframe>
          ) : (
            <img
              src={`${process.env.REACT_APP_API_BASE_URL}/arquivos/${diretorioArquivo}`}
              alt=""
              style={{ maxWidth: 500, maxHeight: 500, objectFit: 'contain' }}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
